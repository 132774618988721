<template>
  <div class="admin-home">
    <b-card class="shadow-sm border-0" title="Welcome to DRSG Admin">
      <b-card-text>
        Manage your site by aditing users and stores. If you are first time
        user, please add a new store first.
      </b-card-text>
      <!-- <b-button variant="brand-primary" :to="{ name: 'create-site' }"
        >Add new site</b-button
      > -->
    </b-card>
    <section class="admin-widgets mt-4">
      <ul class="list-unstyled admin-widgets-list mb-0 pb-0">
        <li class="">
          <div
            class="box shadow-sm d-flex rounded justify-content-between align-items-start"
          >
            <div class="d-flex align-item-center flex-column">
              <span
                class="box-icon bg-brand-light-yellow brand-dark-yellow p-3 rounded"
              >
                <i class="icon-users"></i>
              </span>
              <div class="mt-2">
                <h4 class="body-2">All Users</h4>
                <h4>{{ usersList.length }}</h4>
              </div>
            </div>
            <b-link class="brand-text-gray" :to="{ name: 'create-users' }"
              ><i class="icon-add"></i
            ></b-link>
          </div>
        </li>
        <li class="">
          <div
            class="box shadow-sm d-flex rounded justify-content-between align-items-start"
          >
            <div class="d-flex align-item-center flex-column">
              <span
                class="box-icon bg-brand-light-blue brand-dark-blue p-3 rounded"
              >
                <i class="icon-stores"></i>
              </span>
              <div class="mt-2">
                <h4 class="body-2">All Sites</h4>
                <h4>{{ sitesList.length }}</h4>
              </div>
            </div>
            <b-link class="brand-text-gray" :to="{ name: 'create-site' }"
              ><i class="icon-add"></i
            ></b-link>
          </div>
        </li>
      </ul>
    </section>
    <!-- <section class="store_analytics-section mt-2 p-5">
      <div>
        <h5>Store Analytics is coming soon</h5>
        <p>
          See the store data at a glance and analyze them with DRSG Store
          Analytics.
        </p>
      </div>
      <div class="mt-4">
        <apexchart
          type="area"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </section> -->
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "AdminHome",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Admin Dashboard",
  },
  data() {
    return {
      series: [
        {
          name: "Store Analytics", //name of series
          data: [
            48, 130, 155, 55, 48, 130, 155, 55, 85, 142, 98, 47, 200, 129, 60,
          ], // data to display on y-axis
        },
      ],
      chartOptions: {
        //chartOptions is an object containing all the configs of the chart.
        chart: {
          // height: 350,
          // type: 'line',
          toolbar: {
            // to enable/disable toolbar
            show: false,
          },
        },
        annotations: {
          position: "front", //defining position of annotation: front/back
          points: [
            {
              // x: 'Apr 11, 2000',
              // x: 947529000000,
              x: "11 Oct", //value for x axis
              y: 142, //value for y axis
              marker: {
                //to style the marker of the annotation
                size: 5,
                fillColor: "#fff",
                strokeColor: "lightblue",
                strokeWidth: 2,
              },
              label: {
                // to style the label of the annotation
                // opacity: 0.6,
                borderColor: "#c2c2c2",
                borderWidth: 1,
                borderRadius: 10,
                text: 142,
                offsetX: 30,
                offsetY: 20,
                // mouseEnter: undefined,
                // mouseLeave: undefined,
                style: {
                  background: "#c2c2c2",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Poppins",
                  // cssClass: 'apexcharts-point-annotation-label',
                  padding: {
                    left: 9,
                    right: 9,
                    top: 4,
                    bottom: 4,
                  },
                },
              },
            },
          ],
        },
        stroke: {
          width: 1, //the stroke width defines the width of the drawing line
        },
        dataLabels: {
          enabled: false, //if enabled, the y-axis values will be plotted on the graph
        },
        fill: {
          type: "gradient", //to apply gradient effect below the drawing line
          gradient: {
            shadeIntensity: 1,
            opacityTo: 0.2, //
            opacityFrom: 0.4,
          },
        },
        xaxis: {
          //configure the x axis
          type: "category", //type can be numeric/datetime/category
          tickAmount: 4,
          categories: [
            "1/11/2000",
            "2/11/2000",
            "3/11/2000",
            "4/11/2000",
            "5/11/2000",
            "6/11/2000",
            "7/11/2000",
            "8/11/2000",
            "9/11/2000",
            "10/11/2000",
            "11/11/2000",
            "12/11/2000",
            "1/11/2001",
            "2/11/2001",
            "3/11/2001",
            "4/11/2001",
            "5/11/2001",
            "6/11/2001",
          ],
          labels: {
            formatter: function (value) {
              //formatter function for x values
              return moment(value).format("DD MMM");
            },
            style: {
              fontSize: "14px", //styling the displayed x axis
              fontFamily: "Nunito Sans",
              fontWeight: 400,
            },
          },
        },
        tooltip: {
          x: {
            //defining format for tooltip
            format: "dd/MM/yy HH:mm",
          },
        },
        grid: {
          //to enable/display grid line at the back of chart
          show: true,
          borderColor: "#90A4AE",
          strokeDashArray: 10,
        },
      },
    };
  },
  methods: {
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    ...mapActions("userManager", {
      getAllUsers: "getAll",
    }),
    ...mapActions("siteManager", {
      getAllSites: "getAll",
    }),
  },
  computed: {
    ...mapGetters("userManager", { usersList: "gridList" }),
    ...mapGetters("siteManager", { sitesList: "gridList" }),
  },
  async created() {
    try {
      this.generalSetItem({ resource: "isLoading", payload: true });
      await this.getAllUsers({ userId: this.loggedUserId });
      await this.getAllSites({ userId: this.loggedUserId });
      this.generalSetItem({ resource: "isLoading", payload: false });
    } catch {
      this.generalSetItem({ resource: "isLoading", payload: false });
    }
  },
};
</script>
